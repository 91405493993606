/*media quiery*/
/* @media(min-width: */

.td_ProjVid {
	width: 100%;
}

/*=====Tirp Web Theme=====*/
.td_cScheme1 {
	background-color: #7EC2FE;
}

.td_cScheme2 {
	background-color: #C7E5FE;
}

.td_cScheme3 {
	background-color: #E0E5FE;
}

.td_whiteT {
	color: white;
}

/*=====Tirp Web Font=====*/
@font-face {
  font-family: 'montAlt';
  src: url('../fonts/MontserratAlternates-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montAlt-Bold';
  src: url('../fonts/MontserratAlternates-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

.td_montAlt {
	font-family: 'montAlt';
}

.td_fColor1 {
	color: #0088FE;
}

.td_fFontSz1 {
	font-size: 12px;
}

.td_fFontSz2 {
	font-size: 18px;
}

.td_fFontSz3 {
	font-size: 24px;
}

/* h1, h2, h3, h4, h5, h6, b { */
	/* color: #004DFE; */
/* } */

/*=====END Tirp Web Font=====*/

/*=====END Tirp Web Theme=====*/

.iconSz1 {
	width: 16px;
	height: 16px;
}

.iconSz2 {
	width: 32px;
	height: 32px;
}

.iconSz3 {
	width: 64px;
	height: 64px;
}

.picSz1 {
	width: 600px;
	height: 800px;
}

.picSz2 {
	width: 300px;
	height: 400px;
}

.homeFrontMsg {
	font-weight: bold;
}

.homePic {
	width: 400px;
	height: 400px;
	margin: auto;
	border-radius: 20px;
	border: 2px solid #7EC2FE;
	padding: 0;
}

.homePic2 {
	margin: auto;
	border-radius: 20px;
	border: 2px solid #7EC2FE;
	max-width: 100%;
}

.homeText {
	display: flex;
	align-items: center;
	height: 100%;
	padding: auto 0;
}

.hPgProfPic {
	width: 200px;
	height: 200px;
	padding: 0;
	border-radius: 20px;
	border: 2px solid;
}

.tdSP240v {
	border: 2px solid #7EC2FE;
	border-radius: 12px;
	max-width: 240px;
	max-height: 240px;
}

.tdNoLS {
	list-style: none;
}

/*=====About=====*/
.td_Abt {
	border: 2px solid #7EC2FE;
	border-radius: 20px;
}

.td_AbtProfPic {
	width: 100%;
}

.tdAbtSkilList {
	columns: 2; /* Divide the list into two columns */
	column-gap: 20px; /* Adjust the gap between columns as needed */
	list-style: none; /* Remove the default list bullet points */
	padding: 0 40x; /* Remove default padding */
}

.tdAbtSkilList li {
	display: block; /* Ensure list items are displayed as blocks */
	margin-bottom: 10px; /* Add some spacing between list items */
}

.tdAbtWEttl {
	opacity: 0.7;
}

.b-example-divider {
	width: 100%;
	height: 3rem;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}
/*=====END About=====*/

/*=====FOOTER=====*/
.td_Footer {
	margin-top: 20px;
	padding: 20px 0;
	border-top: 2px solid #7EC2FE;
}

.td_Footer > div > div {
	margin: 0;
}

.td_Footer > div > div > a,
.td_Footer > div > div > h5 {
	padding: 0;
}

.td_Footer > div > div > h6 {
	text-align: right;
}
/*=====END FOOTER=====*/